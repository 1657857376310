@import url('./common.scss');

.Skills{
    .SkillsContainer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .SkillsContents{
            width: 100%;
            height: 100%;
            padding: 0 80px;
        }
    }
}

@media screen and (max-width:900px){
    .Skills{
        .SkillsContainer{
            flex-direction: column;
            .SkillsContents{
                width: 80%;
                padding: 0;
            }
        }
    }
}