@import url('./../common.scss');

.SkillContent{
    margin-bottom: 40px;
    .SkillContentTitle{
        display: flex;
        align-items: center;
        margin-top: 20px;

        .SkillIcon{
            height: 30px;
            width: 30px;
            margin-right: 20px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }

    .SkillsDetails{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        .SkillProgressBar{
            margin: 5px 0;
            width: 100%;
        }
    }
}