@import url('./../common.scss');

.Button{
    display: flex;
    width: auto;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all .2s;

    &.circleButton{
        border-radius: 50%;
    }

    &.secondary{
        border: 1px solid rgb(170, 170, 170);
    }

    &.secondary:hover{
        background-color: rgb(170, 170, 170);
    }

    &.primary{
        color: white;
        background-color: var(--first-color);
    }

    &.primary:hover{
        background-color: var(--first-color-hover);
    }

    &.tertiary{
        background-color: transparent;
        padding: 0!important;
    }

    &.small{
        height: 30px;

        .ButtonIcon{
            width:30px;
            height:30px;
        }
    }

    &.medium{
        height: 40px;
    }

    &.large{
        height: 50px;
    }

    &.iconButton{
        padding: 10px;

        &.small{
            width: 30px;
            height: 30px;
        }
    
        &.medium{
            width: 40px;
            height: 40px;
        }
    
        &.large{
            width: 50px;
            height: 50px;
        }

        .ButtonIcon{
            width:100%;
            height:100%;
        }
    }

    .ButtonIcon{
        width:40px;
        height:40px;
    }    

    .ButtonLabel{
        padding: 0 5px;
    }
}

.ButtonContainer{
    input{
        display: none;
    }
}