.Menu{
    position: relative;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: transparent;
    transition: all .3s;
    position: fixed;
    z-index: 100000;
    display: flex;
    box-shadow: 0 0 10px rgba($color: var(--third-color), $alpha: .5);

    &.FixedMenu{
        background-color: var(--third-color);
    }

    &.activate{
        left: 0px;
    }

    .MenuTitle{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50px;
        height: 100%;
        cursor: pointer;
    }

    .MenuCases{
        display: flex;
        position: absolute;
        right: 0;
    }

    .MenuBurger{
        position: absolute;
        right: 0;
        top: 0;
    }
}

@media screen and (max-width:900px){
    .Menu{
        .MenuTitle{
            left: 20px;
        }
    }
}