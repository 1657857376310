@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400,500,600,700&display=swap');


.Heading{
    color: black;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;

    &.center{
        text-align: center;
    }

    &.uppercase{
        text-transform: uppercase;
    }

    &.color1{
        color: var(--text-color);
    }

    &.color2{
        color: var(--text-color-second);
    }

    &.color3{
        color: var(--text-color-third);
    }

    &.color4{
        color: var(--text-color-four);
    }

    &.h1{
        font-size: 65px;
    }

    &.h2{
        font-size: 45px;
    }

    &.h3{
        font-size: 30px;
    }

    &.h4{
        font-size: 25px;
    }

    &.h5{
        font-size: 22px;
    }

    &.h6{
        font-size: 20px;
    }
}

@media screen and (max-width:1250px) {
    .Heading{
        &.h1{
            font-size: 55px;
        }
    
        &.h2{
            font-size: 40px;
        }
    
        &.h3{
            font-size: 28px;
        }
    
        &.h4{
            font-size: 22px;
        }
    
        &.h5{
            font-size: 22px;
        }
    
        &.h6{
            font-size: 18px;
        }
    }
}

@media screen and (max-width:900px) {
    .Heading{
        &.h1{
            font-size: 45px;
        }
    
        &.h2{
            font-size: 35px;
        }
    
        &.h3{
            font-size: 25px;
        }
    
        &.h4{
            font-size: 22px;
        }
    
        &.h5{
            font-size: 20px;
        }
    
        &.h6{
            font-size: 16px;
        }
    }
}

@media screen and (max-width:500px) {
    .Heading{
        &.h1{
            font-size: 35px;
        }
    
        &.h2{
            font-size: 25px;
        }
    
        &.h3{
            font-size: 22px;
        }
    
        &.h4{
            font-size: 20px;
        }
    
        &.h5{
            font-size: 18px;
        }
    
        &.h6{
            font-size: 14px;
        }
    }
}