@import url('./../common.scss');

.Timeline{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .TimelineContent{
        width: 100%;
        height: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .TimelineBar{
            height: calc(100% - 100px);
            background-color: var(--four-color);
            width: 10px;
            position: absolute;
            top: 50px;
            left: calc(50% - 5px);
            z-index: 100;
            border-radius: 5px;
        }

        .TimelineDots{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            position: relative;
            height: 80%;

            .TimelineDot{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                z-index: 1000;
                justify-content: center;  

                .Dot{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: var(--first-color);
                }

                .TimelineDotDescription{
                    position: absolute;
                }
            }
        }
    }
}

@media screen and (max-width:900px) {
    .Timeline{
        .TimelineContent{
            width: 100%;
            height: 100%;
    
            .TimelineBar{
                display: none;
            }
    
            .TimelineDots{
    
                .TimelineDot{
    
                    .Dot{
                        display: none;
                    }
    
                    .TimelineDotDescription{
                        position: static;
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}