.Home{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .HomeBackground{
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/homeBackground.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-attachment: fixed;
        background-size: cover;
    }

    .HomeBackgroundOpacity{
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    }

    .HomeContent{
        z-index: 100;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .HomeIntroContainer{
            width: 60%;
            padding: 20px;
            border-radius: 8px;

            .HomeIntro{
                margin-bottom: 5px;
            }

            .HomeStatus{
                margin: 10px 0;
            }

            .HomePresentation{
                margin-top: 20px;
            }
        }

        .AboutBtn{
            position: absolute;
            bottom: 15px;
            animation: upAndDown 1s infinite;
        }
    }
}

@keyframes upAndDown {
    from {
        transform: translateY(0px);
    }

    50%{
        transform: translateY(-10px);
    }
  
    to {
        transform: translateY(0px);
    }
  }

@media screen and (max-width:900px) {
    .Home{
        .HomeContent{
            .HomeIntroContainer{
                .HomeIntro{
                    margin-bottom: 5px;
                }
    
                .HomeStatus{
                    display: none;
                }
    
                .HomePresentation{
                    margin-top: 10px;
                }
            }
        }
    }
}