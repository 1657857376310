@import url('./../common.scss');

.BurgerMenu{
    z-index: 100000;

    &.visible{
        .BurgerContent{
            right: 0;
        }

        .BurgerMenuIcon{
            .Button{
                transform: rotate(180deg);
            }
        }    
    }

    .BurgerMenuIcon{
        padding: 5px 20px;
        transition: all .2s;
    }

    .BurgerMenuIcon:hover{
        background-color: var(--first-color);
    }

    .BurgerContent{
        position: fixed;
        top: 60px;
        transition: all .4s;
        right: -100%;
        background-color: var(--third-color);
        width: 300px;
        height: 100vh;
    }
}