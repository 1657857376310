@import url('./../common.scss');

.Carousel{
    display: flex;  
    flex-direction: row;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    .CarouselItems{
        width: 100%;
        height: 100%;
        display: flex;
        transition: all .5s;
        
        .CarouselItem{
            height: 100%;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            &.noTransition{
                transition: none!important;
            }
        }

        &.noTransition{
            transition: none!important;
        }
    }

    .CarouselButton{
        position: absolute;
        top: calc(50% - 20px);
        z-index: 1000;
        box-shadow: 0 0 10 rgba(0, 0, 0, .5);

        &.leftButton{
            left: 10px;
        }

        &.rightButton{
            right: 10px;
        }
    }
}