@import url('./../common.scss');

.TimelineCase{
    max-width: 500px;
    // max-height: 300px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--four-color);
    border-radius: 5px;
    position: relative;

    &.left{
        margin-right: 30px;
    }

    &.right{
        margin-left: 30px;
    }

    .TimelineCaseTitle{
        width: 100%;
        // padding: 20px 20px 10px 20px;
        margin-bottom: 10px;

        .title{
            margin-bottom: 5px;
        }
    
        .subtitle{
            margin-bottom: 5px;
        }

        .time{
            display: flex;
            align-items: center;

            .timeImg{
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                margin-right: 5px;

                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .TimelineCaseDescription{
        // padding: 0 20px 20px 20px;
    }

    .TimelineCaseArrow{
        width: 40px;
        height: 40px;
        position: absolute;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
        left: calc(50% - 20px);

        img{
            width: 100%;
            height: 100%;
        }

        &.left{
            left: -22px;
            right: inherit;
            img{
                transform: rotate(90deg);
            }
        }

        &.right{
            left: inherit;
            right: -22px;
            img{
                transform: rotate(270deg);
            }
        }
    }
}

@media screen and (max-width:900px){
    .TimelineCase{
        margin: 0 20px!important    ;
        .TimelineCaseArrow{
            display: none;
        }
    }
    
}