@import url('./common.scss');

.Contact{
    height: 100%;
    
    .ContactContent{
        display: flex;
        margin: 40px 0;
        width: 70%;

        .ContactCoordonnees{
            width: 350px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .CoordonneeContainer{
                display: flex;
                margin: 20px 0;
                align-items: center;

                &.first{
                    margin-top: 0;
                }

                .CoordonneeIcon{
                    margin-right: 20px;

                    img{
                        width: 40px;
                        height: 40px;
                    }
                }

                .CoordonneeContent{
                    .CoordonneeLabel{
                        margin-bottom: 5px;
                    }

                    .CoordonneeButton{
                        width: 40px;
                    }

                    .CoordonneeImg{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        img{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    
                }

            }
        }

        .ContactEmailForm{
            width: 100%;
            margin-left: 50px;
            display: flex;  
            // justify-content: center;
            flex-direction: column;

            .ContactForm{
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                flex-direction: column;

                .ContactFormTitle{
                    width: 100%;
                    margin-bottom: 10px;
                }

                .ContactFormInput{
                    width: 100%;
                    margin: 15px 0;
                }

                .ContactFormButton{
                    width: 200px;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media screen and (max-width:1050px){
    .Contact{
        .ContactContent{
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 40px 0;

            .ContactCoordonnees{
                width: 100%;
            }

            .ContactEmailForm{
                width: 100%;
                margin: 0;
    
                .ContactForm{
                    align-items: center;

                    .ContactFormInput{
                        width: 100%;
                    }
    
                    .ContactFormButton{
                        width: 100%;
                    }
                }
            }
        }
    }
}