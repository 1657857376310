.Page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.small{
        min-height: calc(100vh - 60px);
    }

    &.type2{
        background-color: var(--third-color);
    }

    &.type3{
        background-color: var(--four-color);
    }

    .PageTitle{
        margin-top: 40px;
    }

    .PageContent{
        width: 100%;
        height: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width:900px){
    .Page{
        .PageContent{
            flex-direction: row;
            margin-bottom: 20px;
        }
    }
}