@import url('./common.scss');

.Experience{
    width: 100%;
    height: 100%;

    .ExperienceContent{
        width: 100%;
        height: 100%;
        display: flex;
        flex-grow: 1;
    }
}