@import url('./../common.scss');

.PopupContent{
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .PopupTitleContent{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;

        .PopupTitle{
            text-align: left;
        }

        .PopupDate{
            margin-top: 4px;
        }

        .PopupSubTitle{
            margin: 10px 0 5px 0;
            padding: 0 40px;
        }
    }

    .PopupImage{
        width: 90%;

        img{
            width: 100%;
            // height: 100%;
        }
    }

    .PopupContainer{
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        width: 90%;

        .PopupContentLabel{
            display: flex;
            margin: 10px 0;
            align-items: center;

            .PopupDescriptionLabel{
                margin-left: 10px;
            }
        }

        .PopupDescription{
            margin-top: 10px;
        }

        .PopupVisu{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 15px 0;

            img{
                width: 100%;
                margin-bottom: 30px;
                box-shadow: 0 0 10px rgba($color: #000000, $alpha: .3);
            }
        }
        
        .PopupSkills{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;

            .PopupSkill{
                padding: 10px 20px;
                background-color: var(--first-color);
                border-radius: 2px;
                margin: 10px 20px 20px 0;
                transition: all .2s;
            }

            .PopupSkill:hover{
                background-color: var(--first-color-hover);
            }
        }
    }
}

@media screen and (max-width:900px){
    .PopupContent{
        .PopupTitleContent{
            .PopupTitle{
                margin: 0 40px;
            }
        }

    
        .PopupContainer{
            .PopupSkills{
    
                .PopupSkill{
                    padding: 10px 10px;
                    background-color: var(--first-color);
                    border-radius: 2px;
                    margin: 10px 5px;
                    transition: all .2s;
                }
    
                .PopupSkill:hover{
                    background-color: var(--first-color-hover);
                }
            }
        }
    }
}