.About{
    display: flex;
    width: 100%;

    .AboutContainer{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .AboutLeftPart{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 80px;

            img{
                width:450px;
            }
        }
    
        .AboutRightPart{
            width: 900px;
            max-height: 100%;
            margin-right: 80px;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .cvButton{
            display: flex;
            margin-top: 20px;
            align-items: center;
        }   
    }
}

@media screen and (max-width: 1250px){
    .About{
        .AboutContainer{
            .AboutLeftPart{  
                img{
                    width:400px;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px){
    .About{
        .AboutContainer{
            flex-direction: column;

            .AboutLeftPart{
                margin: 20px 0;
    
                img{
                    width:80%;
                    max-width: 500px;
                }
            }

            .AboutRightPart{
                width: 80%;
                margin:20px 0;
            }
    
            .cvButton{
                justify-content: center;
            }   
        }
    }
}