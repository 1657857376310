.CaseMenu{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0 30px;
    transition: all .2s;

    .CaseMenuLabel{
        width: 100%;
        display: flex;
        align-items: center;
    }

    .CaseMenuIcon{
        width: 40px;
        .MenuIcon{
            width: 40px;
        }
    }
}

.CaseMenu:hover{
    background-color: #2272FF;
    cursor: pointer;
}

@media screen and (max-width:1250px){
    .CaseMenu{
        padding: 0 20px;
    }
}