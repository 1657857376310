@import url('./../common.scss');

.Input{
    width: 100%;
    
    input, textarea{
        width: calc(100% - 20px);
        padding: 20px 10px;
        border-radius: 5px;
        border: none;
        background-color: var(--four-color);
        font-size: 18px;
        border: none;
    }

    textarea{
        min-height: 100px;
        max-height: 100px;
    }
}