@import url('./../common.scss');

.ProgressBar{
    width: 100%;
    display: flex;
    flex-direction: column;

    .ProgressBarLabel{
        margin:0 10px 5px 0;
    }

    .Bar{
        width: 100%;
        position: relative;
        height: 20px;
        background-color: var(--second-color);

        .BarFill{
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            background-color: var(--first-color);
        }
    }
}