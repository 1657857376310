@import url('./../common.scss');

.ButtonToTop{
    opacity: 0;
    z-index: 10000;
    position: fixed;
    bottom: 10px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba($color:black, $alpha:.7);
    display: flex;
    align-items: center;
    transition: all .3s;
    justify-content: center;
    right: 10px;
    transition: opacity .2s linear;

    &.activate{
        opacity: 1;
    }

    &.desactivate{
        opacity: 0;
        z-index: -1;
    }
}