@import url('./common.scss');

.Projects{
    width: 100%;
    height: 100%;

    .ProjectsContent{
        width: 100%;
        min-height: 100%;
        display: flex;
        margin: 60px 0;
    }
}