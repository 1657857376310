@import url('../common.scss');

.Body{
    color: var(--text-color);
    font-size:20px;
    text-align: justify;
    letter-spacing: 1px;

    &.center{
        text-align: center;
    }

    &.color1{
        color: var(--text-color);
    }

    &.color2{
        color: var(--text-color-second);
    }

    &.color3{
        color: var(--text-color-third);
    }

    &.color4{
        color: var(--text-color-four);
    }

    &.small{
        font-size: 16px;
    }
}

@media screen and (max-width:1250px) {
    .Body{
        font-size:18px;
    }
}

@media screen and (max-width:900px) {
    .Body{
        font-size:16px;
    }
}

@media screen and (max-width:500px) {
    .Body{
        font-size:14px;
    }
}