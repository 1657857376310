@import url('../common.scss');

.Transition{
    width: 100%;
    height: 200px;
    position: relative;

    .TransitionBackground{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-image: url('./../../assets/homeBackground.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-attachment: fixed;
        background-size: cover;
        z-index: 100;
    }

    .TransitionFilter{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: rgba($color: #000000, $alpha: .5);
    }
}