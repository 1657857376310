@import url('./../common.scss');

.CarouselContent{
    width: 500px;
    background-color: var(--first-color);
    display: flex;
    height: 500px;
    // flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all .3s;

    .CarouselContentBackground{
        height: 100%;
        z-index: 1000;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;

        img{
            height: 100%;
        }
    }

    .CarouselContentBackgroundColor{
        z-index: 10000;
        transform: translateY(100%);
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: var(--first-color);
        opacity: .85;
        transition: all .4s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .CarouselContentDescription{
            padding: 20px 40px;
        }
    }
}

@media screen and (max-width:1600px) and (min-width:1300px){
    .CarouselContent{
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width:680px){
    .CarouselContent{
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width:450px){
    .CarouselContent{
        width: 300px;
        height: 300px;
    }
}

.CarouselContent:hover{
    transform: scale(1.05);

    .CarouselContentBackgroundColor{
        transform: translateY(0);
    }
}