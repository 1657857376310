*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

html{
    scroll-behavior: smooth;
}

/* ROOT */

:root{
    /* COLORS */

    --first-color: 	#1F51FF;
    --second-color: #FFFFFF;
    --third-color: #1D1D1D;
    --four-color: #d6d6d6;

    --text-color: black;
    --text-color-second: #FFFFFF;
    --text-color-third: #9e9e9e;
    --text-color-four: #1F51FF;

    --first-color-hover: #4668e4;

    // --container-color : white;
    // --container-color-second : rgb(202, 202, 202);

    
    // --text-color-second: rgb(83, 83, 83);
    // --text-color-third: white;
    // --text-color-four:rgb(172, 172, 172);
    // --text-color-hover:rgb(194, 194, 194);

    // --title-color: black;
    // --title-color-second:rgb(172, 172, 172);
    // --title-color-third: rgb(83, 83, 83);
    // --title-color-four: white;
    
    // --border-color : black;
    // --background-form-item: rgb(223, 223, 223);
}