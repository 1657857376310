@import url('./../common.scss');

.PopupBackground{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;

    .Popup{
        width: 60%;
        height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: var(--second-color);
        border-radius: 5px;
        position: relative;
        
        .PopupCloseButton{
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1000;
        }
    }

    &.visible{
        opacity: 1;
        z-index: 100000;
    }
}

@media screen and (max-width:900px){
    .PopupBackground{
        .Popup{
            width: 90%;
            height: 90%;
        }
    }
}