@import url('./../common.scss');

.Section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 32px;
    height: 100%;

    .SectionTitle{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;

        .SectionIcon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin: 0 16px 0 0;

            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}