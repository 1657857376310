@import url('./common.scss');

.Footer{
    padding: 20px 0;
    width: 100%;
    background-color: var(--third-color);
    flex-direction: column;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}